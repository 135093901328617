.sc_upload_avatar_section {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 5px;
}
.sc_upload_avatar_section .image {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}
.sc_upload_avatar_section .btn_section {
  display: flex;
  flex-direction: column;
}
