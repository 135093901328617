.sc_radio_button_section {
  all: unset;
}
.sc_single_radio_item {
  display: flex;
  cursor: pointer;
  align-items: flex-start;
}
.sc_radio_label_section {
  margin-left: 7px;
  margin-top: -3px;
}

.sc_radio_button_label {
  display: block;
  color: #22100d;
  font-weight: 500;
  font-size: 14px;
}
.sc_radio_button_description {
  color: #263238;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
