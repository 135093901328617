.custom__react__pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.react__paginate__button__container {
  display: flex;
  align-items: center;
  list-style: none;
}
.next__icon {
  width: 2rem;
  color: #000;
}
.pageClassName {
  list-style: none;
}
.sc_pagination_button {
  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 0.3rem;
  font-weight: 400;
  font-size: 16px;
  border: 1px solid #ffdeda;
  background: #ffffff;
}
.sc_next_and_prev_button {
  border: 1px solid #ffdeda;
}
.sc_next_and_prev_button a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nextClassName {
  margin-left: 1rem;
}

.nextClassName a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.previousClassName {
  margin-left: 1rem;
}

.activeClassName {
  background-color: #ff5364;
  color: #ffffff !important;
}

.activeClassName .pageLinkClassName {
  color: #ffffff !important;
}
