.your__avatar{
    color: #392c7d;
    font-size: 18px;
    font-weight: 600;
}

/* FOR COURSE DETAILS CSS STARTS HERE  */
.course__title{
    cursor: pointer;
}
.course__title:hover{
    color: #f66962;
}
.courses__title{
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    color: #212529;
    text-transform: uppercase;
}
.lessons__count{
    color: #392C7D;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}
.video__section{
    max-width: 100%;
    max-height: 420px;
}
.video__introduction{
    color: #F66962;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 10px;
}
.card__title__box__style{
    border-bottom: none;
    border-radius:10px 10px 0px 0px  !important;
}
.card__margin{
    margin-top: -1rem;
    border-top: none;
    border-radius:0px 0px 10px 10px !important;
}
.course__content__title{
    display: flex !important;
}
.course__body__style:hover, .course-card ul .course__content__title:hover,.course__title:hover{
    cursor: pointer;
}
.course__body__style:hover .chapter__name,.course-card ul .course__content__title:hover,.active__title,.course__title:hover .rating-img p{
    color: #F66962;
}
/* FOR COURSE DETAILS CSS END HERE  */

/* FOR PDF VIEWER CSS STARTS HERE  */

.react-pdf__Page__canvas{
  background: #FAFAFA;
  padding: 50px;
}
.download__button{
    width: 25% !important;
}
@media screen and (max-width:767px) {
    .download__button{
        width: 50% !important;
    }

    
}
/* FOR PDF VIEWER CSS END HERE  */

/* PROFILE SECTION CSS STARTS HERE */
.profile__picture__img__wrapper{
    max-width: 100px;
    width: 100%;
    height: 100px;
    margin-right: 10px;
    
}
.profile__picture__img__wrapper img{
    max-width: 100px;
    width: 100%;
    height: 100px;
    object-fit: cover;
}
@media screen and (max-width:1000px) {

    .profile__picture__img__wrapper img{
        max-width: 100px;
        width: 100%;
        height: 100px;
    }
}

@media screen and (max-width:767px) {
    .profile__picture__img__wrapper{
        max-width: 60px;
        width: 100%;
        height: 60px;
    }
    
}
/* PROFILE SECTION CSS END HERE */