.sc_video__loader__wrapper {
  background: rgba(0, 0, 0, 0.7);
  opacity: 5;
  min-height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  z-index: 1000 !important;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sc_circular_progress_bar {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  /* background-color: rgba($color: #000000, $alpha: 0.7); */
}

.sc_uploading__text {
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
}
.circle-bg {
  fill: none;
  stroke: #d0d5dd;
}

.sc_circle-progress {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.sc_circle_text {
  font-size: 14px;
  font-weight: bold;
}
